"use client";

import { useEffect } from "react";

export default function ScrollInit() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }, 0);
  }, []);
  return null;
}

"use client";

import ResponsiveContent from "@app/[lang]/components/MainPage/ResponsiveContent";
import SectionTitle from "@app/[lang]/components/MainPage/SectionTitle";
import Tag from "@app/[lang]/components/MainPage/Tag";
import { Progressbar } from "@hits/hits-ui/progressbar";
import { useSetScrollSnapCurrentIdx } from "@shared/hooks";
import { WindowSizeType, useGetWindowSize } from "@shared/hooks/";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { MdArrowForward, MdChevronLeft, MdChevronRight } from "react-icons/md";

const JournalLink = ({ href }: { href: string }) => {
  return (
    <Link
      className={clsx(
        "flex w-fit items-center rounded-[20px] bg-black/[0.2] px-3 py-[8.5px] leading-none transition-colors",
        "leading-[150%] text-white/[0.8] transition-colors duration-200 hover:bg-[#1a1a1a]",
        "pc:text-lg flex gap-2",
        "pc_1440:text-[18px]",
        "pc_1024_r:text-[15px]",
        "text-xs",
      )}
      href={href}
      target="_blank"
    >
      <span>Read more</span>
      <MdArrowForward />
    </Link>
  );
};
const JournalOrigin = ({ children }: { children: ReactNode }) => {
  return (
    <p
      className={clsx(
        "font-medium leading-[150%] text-white",
        "pc_1440:text-[18px] pc_1440:min-h-[55px]",
        "pc_1024_r:text-[15px] pc_1024_r:min-h-11",
        "min-h-[38.5px] text-xs",
      )}
    >
      {children}
    </p>
  );
};

const JournalTitle = ({ children }: { children: ReactNode }) => {
  return (
    <h4
      className={clsx(
        "font-semibold leading-[140%] text-white",
        "pc_1440:text-[26px] pc_1440:min-h-[211px]",
        "pc_1024_r:text-[20px] pc_1024_r:min-h-[168px]",
        "min-h-[147px] text-[18px]",
      )}
    >
      {children}
    </h4>
  );
};

const Card = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        "flex w-full min-w-[270px] flex-col gap-[35px] rounded-[10px] border-[2px] border-[#3D414A] bg-[#272C36] transition-colors hover:bg-gradient-to-tr hover:from-[#0D2A54] hover:to-[#0D1E38]",
        "pc:min-w-[384px] pc:rounded-[20px] pc:gap-10",
        "pc_1440:gap-10 pc_1440:p-6 pc_1440:pb-10",
        "pc_1024_r:p-5 pc_1024_r:pb-8",
        "p-3.5 pb-7",
        className,
      )}
    >
      {children}
    </div>
  );
};

const TagVariant: {
  [key: string]: "green-border" | "blue-border" | "purple-border";
} = {
  hyperBinding: "green-border",
  hyperDesign: "blue-border",
  hyperAdmet: "purple-border",
};
const TagText: {
  [key: string]: string;
} = {
  hyperBinding: "Hyper Binding",
  hyperDesign: "Hyper Design",
  hyperAdmet: "Hyper ADMET",
};

const CardInfo = [
  {
    tag: "hyperBinding",
    title:
      "PIGNet: a physics-informed deep learning model toward generalized drug–target interaction predictions.",
    origin: "Chem. Sci., Advance Article (2022)",
    link: "https://pubs.rsc.org/en/content/articlelanding/2022/sc/d1sc06946b",
  },
  {
    tag: "hyperDesign",

    title:
      "Molecular generative model based on adversarially regularized autoencoder.",
    origin: "Journal of Chemical Information and Modeling, 60, 29 (2020)",
    link: "https://pubs.acs.org/doi/abs/10.1021/acs.jcim.9b00694",
  },
  {
    tag: "hyperDesign",
    title:
      "Molecular generative model with conditional variational autoencoder.",
    origin: "Journal of Cheminformatics, 10, 31 (2018)",
    link: "https://jcheminf.biomedcentral.com/articles/10.1186/s13321-018-0286-7",
  },
  {
    tag: "hyperDesign",
    title: "Scaffold-based molecular design with graph generative model.",
    origin: "Chemical Science, 11, 1153 (2020)",
    link: "https://pubs.rsc.org/en/content/articlehtml/2020/sc/c9sc04503a",
  },
  {
    tag: "hyperBinding",
    title:
      "A Bayesian graph convolutional network for reliable prediction of molecular properties with uncertainty quantification.",
    origin: "Chemical Science, 10, 8438 (2019)",
    link: "https://pubs.rsc.org/en/content/articlehtml/2019/sc/c9sc01992h",
  },
  {
    tag: "hyperBinding",
    title: "Attention-and gate-augmented graph convolutional network.",
    origin: "arXiv:1805.10988",
    link: "https://arxiv.org/abs/1805.10988",
  },
  {
    tag: "hyperBinding",
    title: "Predicting drug-target interaction using graph neural network.",
    origin: "Journal of Chemical Information and Modeling, 59, 3981 (2019)",
    link: "https://pubs.acs.org/doi/10.1021/acs.jcim.9b00387",
  },
  {
    tag: "hyperAdmet",
    title: "Drug-likeness scoring based on unsupervised learning.",
    origin: "Chemical Science, 13, 554 (2022)",
    link: "https://pubs.rsc.org/en/content/articlelanding/2022/sc/d1sc05248a",
  },
  {
    tag: "hyperDesign",
    title:
      "Molecular Generative Model via Retrosynthetically Prepared Chemical Building Block Assembly.",
    origin: "Advanced Science, 10, 2206674 (2023)",
    link: "https://onlinelibrary.wiley.com/doi/full/10.1002/advs.202206674",
  },
];

// 3개씩 페이지네이션

export default function FifthSection() {
  const { windowSize } = useGetWindowSize();
  const t = useTranslations("HOME.Main.5");
  const [page, setPage] = useState(1);
  const overflowContainerRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState(0);

  // overflow-x되는 container에 ref걸기
  // 해당 ref width offsetWidth, scrollWidth으로 progress bar 계산
  //
  useEffect(() => {
    const handleScroll = () => {
      if (overflowContainerRef.current) {
        const container = overflowContainerRef.current;
        const progress =
          ((container.offsetWidth + container.scrollLeft) /
            container.scrollWidth) *
          100;
        setProgress(progress >= 100 ? 100 : progress);
      }
    };
    if (overflowContainerRef.current) {
      const container = overflowContainerRef.current;
      const progress =
        ((container.offsetWidth + container.scrollLeft) /
          container.scrollWidth) *
        100;
      setProgress(progress);
    }
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);
  return (
    <div
      className={clsx(
        "relative z-[1] flex max-h-[1200px] bg-[#11141E] pb-20",
        "elg:pt-[75px]",
        "smtb:pt-20",
        "pt-[50px]",
      )}
    >
      <ResponsiveContent className="flex w-full flex-col">
        {windowSize > WindowSizeType.tablet_480 ? (
          <>
            <SectionTitle as="h3" className="!mb-0">
              {t.rich("title", {
                br: () => <br />,
              })}
            </SectionTitle>
            <div className="my-5 flex justify-end gap-1">
              <button
                aria-label="prev"
                className="hover:bg-gray-w-900 rounded-lg fill-white active:bg-[#52585D] disabled:pointer-events-none"
                disabled={page === 1}
                onClick={() => {
                  setPage((p) => p - 1);
                }}
              >
                <MdChevronLeft
                  className={clsx(
                    page === 1 ? "fill-[#5f5f5f]" : "fill-white",
                    "h-10 w-10",
                  )}
                />
              </button>
              <button
                aria-label="next"
                className="hover:bg-gray-w-900 rounded-lg fill-white active:bg-[#52585D] disabled:pointer-events-none"
                disabled={page === 3}
                onClick={() => {
                  setPage((p) => p + 1);
                }}
              >
                <MdChevronRight
                  className={clsx(
                    page === 3 ? "fill-[#5f5f5f]" : "fill-white",
                    "h-10 w-10",
                  )}
                />
              </button>
            </div>

            <div
              className="no-scrollbar flex gap-6 overflow-x-auto pb-10"
              ref={overflowContainerRef}
            >
              {CardInfo.map((info, index) => {
                if (index < (page - 1) * 3 || index >= page * 3) return null;
                return (
                  <Card key={index}>
                    <Tag
                      text={TagText[info.tag]}
                      variant={TagVariant[info.tag]}
                    />
                    <JournalTitle>{info.title}</JournalTitle>
                    <JournalOrigin>{info.origin}</JournalOrigin>
                    <JournalLink href={info.link} />
                  </Card>
                );
              })}
            </div>
            <Progressbar
              bgColor="bg-gray-w-900"
              indicatorColor="bg-[#f0f0f0]"
              progress={progress}
              rootClassName="mb-10 h-[4px] rounded-lg elg:hidden"
            />
          </>
        ) : (
          <ScrollSnapView />
        )}
      </ResponsiveContent>
    </div>
  );
}
function ScrollSnapView() {
  const t = useTranslations("HOME.Main.5");
  const [index, setIndex] = useState(0);

  const { scrollWrapperRef } = useSetScrollSnapCurrentIdx({
    setCurrentIdx: setIndex,
    totalCount: 9,
  });

  return (
    <>
      <SectionTitle as="h3" className="!mb-0">
        {t.rich("titleSummary", {
          br: () => <br />,
        })}
      </SectionTitle>
      <div className="flex flex-col items-center">
        <div
          className="no-scrollbar flex w-screen snap-x snap-mandatory gap-6 overflow-x-auto px-5 py-10"
          ref={scrollWrapperRef}
        >
          {CardInfo.map((info, index) => {
            return (
              <Card className="snap-center" key={index}>
                <Tag text={TagText[info.tag]} variant={TagVariant[info.tag]} />
                <JournalTitle>{info.title}</JournalTitle>
                <JournalOrigin>{info.origin}</JournalOrigin>
                <JournalLink href={info.link} />
              </Card>
            );
          })}
        </div>
        <Progressbar
          bgColor="bg-gray-w-900 max-w-[350px]"
          indicatorColor="bg-[#f0f0f0]"
          progress={((index + 1) / 9) * 100}
          rootClassName="mb-10 h-[4px] rounded-lg elg:hidden"
        />
      </div>
    </>
  );
}

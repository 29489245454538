import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/.pnpm/next-intl@3.19.4_next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opente_27csy4seref55zcruk63itlvca/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/.pnpm/next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opentelemetry+api@1.9.0_kc3mbx5rlm3t7mkfz7ycpcxtj4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/.pnpm/next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opentelemetry+api@1.9.0_kc3mbx5rlm3t7mkfz7ycpcxtj4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/HomePageGNB/ConditionalButtonsByLogin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/HomePageGNB/HamburgerMenu/HamburgerMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/HomePageGNB/SupportButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/MainPage/FifthSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/MainPage/FourthSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/MainPage/HyperLabStartButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/MainPage/ScrollInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/MainPage/ThirdSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/MainPage/VideoContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/shared/ui/language/language-select-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownRoot"] */ "/app/packages/hits-ui/src/dropdown/round.dropdown.tsx");

"use client";

import clsx from "clsx";
import dynamic from "next/dynamic";

const VideoJs = dynamic(() => import("@features/home/ui/video-js"), {
  ssr: false,
});

export default function VideoContent() {
  return (
    <div
      className={clsx(
        "relative max-h-[201px] bg-black sm:max-h-[248px] md:max-h-[397px]",
        "max-h-[624px] w-full pb-0 pt-[calc(620/1200*100%)] lg:rounded-[10px]",
      )}
    >
      <VideoJs />
    </div>
  );
}

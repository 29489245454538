"use client";

import ResponsiveContent from "@app/[lang]/components/MainPage/ResponsiveContent";
import SectionTitle from "@app/[lang]/components/MainPage/SectionTitle";
import { Progressbar } from "@hits/hits-ui/progressbar";
import { useSetScrollSnapCurrentIdx } from "@shared/hooks";
import { WindowSizeType, useGetWindowSize } from "@shared/hooks/";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import { useRef } from "react";

const images = [
  "hyper-screening.png",
  "hyper-binding.png",
  "hyper-design.png",
  "hyper-admet.png",
];

type SubtitleKey = "hsSubtitle" | "hbSubtitle" | "hdSubtitle" | "haSubtitle";

const nav: {
  title: string;
  subtitleKey: SubtitleKey;
  alt: string;
}[] = [
  {
    title: "Hyper Screening",
    subtitleKey: "hsSubtitle",
    alt: "hyper-screening",
  },
  {
    title: "Hyper Binding",
    subtitleKey: "hbSubtitle",
    alt: "hyper-binding",
  },
  {
    title: "Hyper Design",
    subtitleKey: "hdSubtitle",
    alt: "hyper-design",
  },
  {
    title: "Hyper ADME/T",
    subtitleKey: "haSubtitle",
    alt: "hyper-adme/t",
  },
];
const currentIndexAtom = atom(0);
export default function FourthSection() {
  const [index, setIndex] = useAtom(currentIndexAtom);
  const t = useTranslations("HOME.Main.4");
  const locale = useLocale();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { windowSize } = useGetWindowSize();

  const handleMove = (index: number) => {
    setIndex(index);
  };

  return (
    <div
      className={clsx(
        "relative z-[1] flex bg-[#11141E]",
        "pb-[61px] pt-10",
        "pc_1440:pt-[110px] pc_1440:pb-[110px]",
        "pc_1024_r:py-[62px]",
        "tablet_480_r:pt-[67px] tablet_480_r:pb-[82px]",
        "tablet_768_r:pt-[67px] tablet_768_r:pb-[82px]",
      )}
      ref={wrapperRef}
    >
      <ResponsiveContent
        className={clsx(
          "flex flex-col items-center justify-center",
          "h-[626px]",
          "pc_1440:h-[1075px]",
          "pc_1024_r:h-[900px]",
          "tablet_480_r:h-[700px]",
          "tablet_768_r:h-[700px]",
        )}
      >
        <SectionTitle>
          <p className="pc_1024:whitespace-nowrap mobile:px-10 mobile_390_r:px-10 whitespace-pre-line">
            {t("title")}
          </p>
        </SectionTitle>
        {windowSize > WindowSizeType.tablet_480 ? (
          <div className="flex h-full w-full flex-col items-center">
            <div
              className={clsx(
                "mb-10 flex w-full justify-between self-center",
                "pc:gap-[33px]",
                "lgtb:gap-6",
                "gap-[12px]",
                "smmb:gap-1",
              )}
            >
              {nav.map((item, i) => (
                <div
                  className={clsx("flex w-full flex-col items-center gap-3")}
                  key={i}
                >
                  <button
                    className={clsx(
                      "w-full select-none text-center font-semibold",
                      "relative whitespace-nowrap",
                      "transition-colors duration-300",
                      "pc_1440:text-[26px] pc_1440:h-[54px] pc_1440:rounded-[10px]",
                      "pc_1024_r:text-[20px] pc_1024_r:h-[43px] pc_1024_r:rounded-[8px]",
                      "tablet_768_r:text-[14px]",
                      "tablet_480_r:text-[12px]",
                      "h-[34px] rounded-md text-[14px]",
                      index === i
                        ? "bg-blue-600 text-white"
                        : "bg-[#17456F] text-blue-400",
                      i !== 3 &&
                        "after:absolute after:top-1/2 after:h-px after:-translate-y-1/2 after:bg-blue-400",
                      "after:-right-full after:w-full",
                    )}
                    onClick={() => handleMove(i)}
                  >
                    {item.title}
                  </button>
                  <h3
                    className={clsx(
                      "text-blue-000 text-center text-[10px] font-medium",
                      "smmb:whitespace-pre-wrap",
                      "tablet_480_r:text-[10px]",
                      "tablet_768_r:text-[14px]",
                      "pc_1024_r:text-[14px]",
                      "pc_1440:text-base",
                    )}
                  >
                    {t(item.subtitleKey)}
                  </h3>
                </div>
              ))}
            </div>
            <div className="relative mx-5 h-full w-full">
              <div className="flex flex-1 self-start">
                {images.map((image, i) => (
                  <Image
                    alt={nav[i].alt}
                    className={clsx(
                      "absolute left-1/2 -translate-x-1/2 rounded-lg object-cover",
                      "pc_1440:max-w-[1200px] pc_1024_r:max-w-[960px] tablet_768_r:max-w-[748px] tablet_480_r:max-w-[652px] w-full max-w-[350px]",
                      "pc_1440:h-[700px] pc_1024_r:h-[560px] tablet_768_r:h-[356px] tablet_480_r:h-[356px] w-[350px]",
                      index === i ? "opacity-100" : "opacity-0",
                      index === 3 && "opacity-100",
                      "transition-opacity duration-300",
                    )}
                    height={1120}
                    key={i}
                    src={`/assets/mainPage/fourthSection/${locale}/${image}`}
                    width={1800}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <ScrollSnapView />
        )}
        <Progressbar
          bgColor="bg-gray-w-900"
          indicatorColor="bg-[#f0f0f0]"
          progress={((index + 1) / 4) * 100}
          rootClassName="h-[4px] rounded-lg"
        />
      </ResponsiveContent>
    </div>
  );
}

function ScrollSnapView() {
  const [index, setIndex] = useAtom(currentIndexAtom);
  const t = useTranslations("HOME.Main.4");
  const locale = useLocale();

  const { scrollWrapperRef } = useSetScrollSnapCurrentIdx({
    setCurrentIdx: setIndex,
    totalCount: nav.length,
  });

  return (
    <div
      className="no-scrollbar mb-10 flex h-full w-full snap-x snap-mandatory items-center overflow-auto"
      ref={scrollWrapperRef}
    >
      {Array.from({ length: nav.length }).map((_, idx) => (
        <div
          className="flex h-full min-w-[350px] snap-center flex-col items-center"
          key={idx}
        >
          <span
            className={clsx(
              "flex w-[130px] select-none items-center justify-center font-semibold",
              "h-[34px] rounded-md text-[14px]",
              "mb-3 bg-blue-600 text-white",
            )}
          >
            {nav[idx].title}
          </span>
          <h3 className={clsx("text-blue-000 mb-5 text-sm font-bold")}>
            {t(nav[idx].subtitleKey)}
          </h3>
          <Image
            alt={nav[idx].alt}
            className={clsx(
              "h-[350px] w-[350px] rounded-lg object-cover duration-300",
              idx === index ? "opacity-100" : "opacity-20",
            )}
            height={700}
            key={idx + "img"}
            src={`/assets/mainPage/fourthSection/${locale}/${images[idx]}`}
            width={1200}
          />
        </div>
      ))}
    </div>
  );
}

"use client";

import { useGetUserConfigDataWrapper } from "@entities/user-config/api";
import { cn } from "@hits/hits-ui/utils/classname";
import { objectToQueryString } from "@shared/utils/url/object-to-query-string";
import Link from "next/link";

export default function HyperLabStartButton({
  title,
  locale,
  session,
}: {
  title: string;
  locale: string;
  session: Record<string, never> | null;
}) {
  const { data: userConfigData } = useGetUserConfigDataWrapper({
    enabled: !!session,
  });
  const startHref = (() => {
    if (!session) {
      return `/${locale}/auth/signup`;
    }
    if (userConfigData?.recentPagePath || userConfigData?.lastLabSpaceId) {
      const params = objectToQueryString({
        redirectUrl: userConfigData.recentPagePath,
        labSpaceId: userConfigData.lastLabSpaceId,
      });
      return `/${locale}/lab/auth/2fa${params}`;
    }
    return `/${locale}/lab-list`;
  })();

  return (
    <Link
      className={cn(
        "text-gray-c-900 hover:bg-home-free-trial-hover flex items-center justify-center rounded-[40px] bg-[#26F1EA] text-center text-lg font-semibold",
        "tablet_480:text-lg pc_1440:text-[20px] text-[16px]",
        "pc_1440:h-[60px] pc_1440:w-[270px]",
        "h-[54px] w-[200px]",
      )}
      href={startHref}
      target={session ? "_self" : "_blank"}
    >
      {title}
    </Link>
  );
}

import { cn } from "@hits/hits-ui/utils/classname";

interface Props {
  variant:
    | "green"
    | "blue"
    | "purple"
    | "right-green"
    | "green-border"
    | "blue-border"
    | "purple-border"
    | "right-green-border";
  // 02FFC2 // 02C2FF // CCA4FF // 26F1EA
  text: string;
  className?: string;
}
export default function Tag({ variant, text, className = "" }: Props) {
  return (
    <span
      className={cn(
        "opacity-90",
        "px-3 py-2.5",
        "text-base",
        "w-fit text-center font-medium !leading-[100%] text-[#02142E]",
        "pc_1440:text-lg pc_1024_r:text-sm text-xs",
        "pc_1440:rounded-lg pc_1024_r:rounded-md rounded",
        variant === "green" && "bg-[#02FFC2]",
        variant === "blue" && "bg-[#02C2FF]",
        variant === "purple" && "bg-[#CCA4FF]",
        variant === "right-green" && "bg-[#26F1EA]",
        variant === "green-border" && "border border-[#02FFC2] text-[#02FFC2]",
        variant === "blue-border" && "border border-[#02C2FF] text-[#02C2FF]",
        variant === "purple-border" && "border border-[#CCA4FF] text-[#CCA4FF]",
        variant === "right-green-border" &&
          "border border-[#26F1EA] text-[#26F1EA]",
        className,
      )}
    >
      {text}
    </span>
  );
}

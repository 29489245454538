"use client";

import ResponsiveContent from "./ResponsiveContent";
import SectionTitle from "@app/[lang]/components/MainPage/SectionTitle";
import Tag from "@app/[lang]/components/MainPage/Tag";
import { Progressbar } from "@hits/hits-ui/progressbar";
import { useSetScrollSnapCurrentIdx } from "@shared/hooks";
import { WindowSizeType, useGetWindowSize } from "@shared/hooks/";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import { type ReactNode } from "react";

const cardImages = [
  {
    src: "hit-to-lead.png",
    alt: "hit-to-lead",
  },
  {
    src: "experiment-priority.png",
    alt: "experiment-Priority",
  },
  {
    src: "3d-structure-analysis.png",
    alt: "3d-structure-analysis",
  },
  {
    src: "hyper-screening.png",
    alt: "hyper-screening",
  },
  {
    src: "easy-to-start.png",
    alt: "easy-to-start",
  },
  {
    src: "seamless-flow.png",
    alt: "seamless-flow",
  },
];

const CardTitle = ({ children }: { children: ReactNode }) => (
  <h3
    className={clsx(
      "font-medium leading-[150%] text-white",
      "pc_1440:text-[26px]",
      "pc_1024_r:text-[20px]",
      "text-[18px]",
    )}
  >
    {children}
  </h3>
);

const currentIndexAtom = atom(0);

export default function ThirdSection() {
  const t = useTranslations("HOME.Main.3");
  const locale = useLocale();
  const { windowSize } = useGetWindowSize();

  return (
    <div className="relative z-[1] bg-[#11141E] pb-[100px] pt-10">
      <ResponsiveContent>
        <div
          className={clsx(
            "flex w-full flex-col items-center justify-center pt-[50px]",
          )}
        >
          <SectionTitle className="pc:mb-[60px] pc:text-[44px] tablet:text-[32px] mb-10">
            {t("title")}
          </SectionTitle>
          {windowSize > WindowSizeType.tablet_480 ? (
            <div
              className={clsx("grid-cols-2/3 grid w-full grid-cols-2 gap-6")}
            >
              {cardImages.map(({ src, alt }, index) => {
                const fileSrc =
                  `/assets/mainPage/thirdSection/${locale}/${src}` || "";
                const tagKey = `tag${index + 1}` as
                  | "tag1"
                  | "tag2"
                  | "tag3"
                  | "tag4"
                  | "tag5"
                  | "tag6";
                const cardTextKey = `cardText${index + 1}` as
                  | "cardText1"
                  | "cardText2"
                  | "cardText3"
                  | "cardText4"
                  | "cardText5"
                  | "cardText6";
                return (
                  <div
                    className={clsx(
                      "flex w-full flex-col rounded-xl border-2 border-[#2A2F38] bg-[#1D222C]",
                    )}
                    key={index}
                  >
                    <div
                      className={clsx(
                        "pc_1024:gap-5 pc_1024:p-6 flex flex-col gap-4 p-4",
                        "pc_1024:pb-[60px] pb-10",
                      )}
                    >
                      <Tag text={t(tagKey)} variant="right-green" />
                      <CardTitle>
                        {t.rich(cardTextKey, {
                          br: () => <br />,
                        })}
                      </CardTitle>
                    </div>
                    <div className={clsx("flex w-full flex-1 items-center")}>
                      <Image
                        alt={alt}
                        className="relative h-full w-full object-contain"
                        height={800}
                        src={fileSrc}
                        width={1200}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <ScrollSnapView />
          )}
        </div>
      </ResponsiveContent>
    </div>
  );
}

function ScrollSnapView() {
  const [index, setIndex] = useAtom(currentIndexAtom);
  const t = useTranslations("HOME.Main.3");
  const locale = useLocale();

  const { scrollWrapperRef } = useSetScrollSnapCurrentIdx({
    setCurrentIdx: setIndex,
    totalCount: cardImages.length,
  });

  return (
    <div className="flex flex-col items-center gap-10">
      <div
        className={clsx(
          "no-scrollbar flex w-screen snap-x snap-mandatory gap-6 overflow-auto px-5",
        )}
        ref={scrollWrapperRef}
      >
        {cardImages.map(({ src, alt }, index) => {
          const fileSrc =
            `/assets/mainPage/thirdSection/${locale}/${src}` || "";
          const tagKey = `tag${index + 1}` as
            | "tag1"
            | "tag2"
            | "tag3"
            | "tag4"
            | "tag5"
            | "tag6";
          const cardTextKey = `cardText${index + 1}` as
            | "cardText1"
            | "cardText2"
            | "cardText3"
            | "cardText4"
            | "cardText5"
            | "cardText6";
          return (
            <div
              className={clsx(
                "flex min-w-[318px] snap-center flex-col rounded-xl border-2 border-[#2A2F38] bg-[#1D222C]",
              )}
              key={index}
            >
              <div className="flex flex-col gap-4 p-4 pb-10">
                <Tag
                  className="rounded px-1.5 py-1 text-[12px]"
                  text={t(tagKey)}
                  variant="right-green"
                />
                <CardTitle>
                  {t.rich(cardTextKey, {
                    br: () => <br />,
                  })}
                </CardTitle>
              </div>
              <div className={clsx("flex w-full flex-1 items-center")}>
                <Image
                  alt={alt}
                  className="!relative h-full w-full object-contain"
                  fill
                  src={fileSrc}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Progressbar
        bgColor="bg-gray-w-900 max-w-[350px]"
        indicatorColor="bg-[#f0f0f0]"
        progress={((index + 1) / 6) * 100}
        rootClassName="h-[4px] rounded-lg"
      />
    </div>
  );
}

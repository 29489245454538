import clsx from "clsx";
import { ReactNode } from "react";

export default function ResponsiveContent({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <section
      className={clsx(
        "pc_1440:max-w-[1200px] pc_1024_r:max-w-[960px] tablet_768_r:max-w-[748px] tablet_480_r:max-w-[672px] tablet_480_r:px-5 mx-auto w-full max-w-[350px]",
        className,
      )}
    >
      {children}
    </section>
  );
}

import * as Progress from "@radix-ui/react-progress";
import React from "react";

export interface ProgressbarProps {
  progress?: number;
  indeterminate?: boolean;
  rootClassName?: string;
  indicatorClassName?: string;
  width?: string;
  height?: string;
  bgColor?: string;
  indicatorColor?: string;
}

export const Progressbar = ({
  progress = 0,
  indeterminate = false,
  rootClassName = "",
  indicatorClassName = "",
  width = "w-full",
  height = "h-2",
  bgColor = "",
  indicatorColor = "",
}: ProgressbarProps) => {
  return (
    <Progress.Root
      aria-label="progressbar-label"
      className={`overflow-y-hidden rounded-[10px] ${bgColor} ${width} ${height} ${rootClassName}`}
      value={progress}
    >
      {indeterminate ? (
        <Progress.Indicator
          aria-label="progressbar-label"
          className="animate-progressIndeterminate h-full w-[250px] rounded-[10px] bg-blue-600"
        />
      ) : (
        <Progress.Indicator
          aria-label="progressbar-label"
          className={`animate-infinite h-full w-full ${
            progress !== 100 && "animate-pulse"
          } ${indicatorColor} ${indicatorClassName} cubic-bezier(0.4, 0, 0.2, 1) transition-all duration-200 `}
          style={{ transform: `translateX(-${100 - progress}%)` }}
        />
      )}
    </Progress.Root>
  );
};

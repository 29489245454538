import { cn } from "@hits/hits-ui/utils/classname";
import type { ReactNode } from "react";

const TITLE_CLASS =
  "mt-8 w-full pc_1440:text-[44px] pc_1024_r:text-[35px] tablet_480_r:text-[32px] tablet_768_r:text-[32px] pc_1440:mb-[60px] pc_1024:mb-12 mb-10 text-[28px] whitespace-normal break-keep text-center font-semibold text-white mb-10 pc:mb-[60px] leading-[160%]";

export default function SectionTitle({
  as = "h2",
  className,
  children,
}: {
  as?: "h2" | "h3";
  className?: string;
  children?: ReactNode;
}) {
  if (as === "h2") {
    return <h2 className={cn(TITLE_CLASS, className)}>{children}</h2>;
  } else if (as === "h3") {
    return <h3 className={cn(TITLE_CLASS, className)}>{children}</h3>;
  }

  return null;
}
